import axios from 'axios'
import config from '../utils/config'
// 创建axios实例
const baseUrl = config.BASE_URL
const requestTimeout = config.REQUEST_TIMEOUT
const service = axios.create({
  baseURL: baseUrl,
  timeout: requestTimeout // 请求超时时间
})
const serviIntercept = () => {
  if (!config.SERVICE_INTERCEPT) {
    return
  }
  // 请求拦截拦截器
  service.interceptors.request.use(
    config => {
      return config
    },
    error => {
      console.log(error)
      Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    res => res,
    // 请求失败情况
    error => {
      const { response } = error
      if (response) {
        return Promise.reject(response)
      } else {
        if (error.message) {
          console.log(error.message)
        } else {
          console.log('服务故障，请检查！')
        }
        return Promise.resolve()
      }
    })
}
// 响应拦截方法
serviIntercept()

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    service.put(url, params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export default service
