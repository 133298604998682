<template>
  <div class="layout-box flex-column-box">
    <!--巨图-->
    <van-image
      v-if="tabbarActive !== showActiveNum"
      width="100%"
      height="150px"
      fit="cover"
      :src="imageFile"
    />
    <!-- <van-notice-bar v-if="tabbarActive !== showActiveNum" left-icon="volume-o" :scrollable="false">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item @click="toDynamicInfo(1)">依托高水平医疗机构，加速我院药物临床试验机构成长</van-swipe-item>
        <van-swipe-item @click="toDynamicInfo(2)">党史学习教育 | “沉浸式”体验，让党史学习入脑入心</van-swipe-item>
        <van-swipe-item @click="toDynamicInfo(3)">健康惠民-党员先行｜看！这些医务工作者在干什么？</van-swipe-item>
      </van-swipe>
    </van-notice-bar> -->
    <div class="middle-box">
      <!--宫格菜单-->
      <van-grid :column-num="3" v-if="tabbarActive !== showActiveNum">
        <van-grid-item v-for="(item, index) in menuList[tabbarActive].childList" :key="`frid${index}`" @click="toPage(item.path)" >
          <i class="grid-icon iconfont iconfont-blue" slot="icon" :class="item.icon" />
          <span class="grid-text" slot="text">{{item.title}}</span>
        </van-grid-item>
      </van-grid>

      <!--我的菜单-->
      <van-cell-group v-if="tabbarActive === showActiveNum">
        <van-cell
          v-for="(item, index) in menuList[tabbarActive].childList"
          :key="`cell${index}`"
          is-link
          :title="item.title"
          @click="toPage(item.path)"
        >
          <i slot="icon" class="cell-left-icon iconfont iconfont-blue" :class="item.icon"></i>
        </van-cell>
      </van-cell-group>
    </div>

    <!--底部导航-->
    <van-tabbar v-model="tabbarActive">
      <van-tabbar-item v-for="(item, index) in tabbarList" :key="`tabbar${index}`" @click="changeTabbarActive(index)">
        <div class="tabbar-title">{{item.title}}</div>
        <i slot="icon" class="tabbar-icon iconfont" :class="item.icon" />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import imageFile from '../../assets/images/about-banner-rh.png'
import mixin from '../../mixins'
export default {
  name: 'Layout',
  mixins: [mixin],
  data() {
    return {
      openId: '',
      text: '',
      imageFile: imageFile,
      menuList: [
        {
          name: '门诊',
          childList: [
            {
              title: '我的就诊人',
              icon: 'icon-jiuzhenren',
              path: '/myPatient'
            },
            // {
            //   title: '预约挂号',
            //   icon: 'icon-yuyueguahao',
            //   path: '/yuyueguahao'
            // },
            // {
            //   title: '我的排队',
            //   icon: 'icon-paiduishu',
            //   path: '/myline'
            // },
            // {
            //   title: '门诊缴费',
            //   icon: 'icon-menzhenjiaofei',
            //   path: '/myPatient?url=menzhenjiaofei'
            // },
            // {
            //   title: '报告查询',
            //   icon: 'icon-baogaochaxun',
            //   path: '/myPatient?url=baogaolist'
            // },
            // {
            //   title: '体检报告',
            //   icon: 'icon-baogaochaxun',
            //   path: '/myPatient?url=tijianlist'
            // },
            {
              title: '门诊报告',
              icon: 'icon-baogaochaxun',
              path: '/myPatient?url=outpatient'
            },
            // {
            //   title: '挂号记录',
            //   icon: 'icon-guahaojiluchaxun',
            //   path: '/guahaojilu'
            // },
            // {
            //   title: '门诊缴费记录',
            //   icon: 'icon-jiaofeijilu',
            //   path: '/menzhenjiaofeijilu'
            // },
            // {
            //   title: '核酸缴费',
            //   icon: 'icon-jiaofeijilu',
            //   path: '/pay?feeType=1'
            // },
            // {
            //   title: '抗原检测',
            //   icon: 'icon-jiaofeijilu',
            //   path: '/pay?feeType=2'
            // },
            // {
            //   title: '预检分诊表',
            //   icon: 'icon-jiaofeijilu',
            //   path: '/yujianfenzhen'
            // },
            // {
            //   title: '核酸报告',
            //   icon: 'icon-baogaochaxun',
            //   path: '/hesuanbaogao?url=hesuanbaogao/list'
            // },
            // {
            //   title: '成人疫苗预约',
            //   icon: 'icon-guahaojiluchaxun',
            //   path: '/registered?id=030801'
            // }
          ]
        },
        {
          name: '住院',
          active: 1,
          childList: [
            {
              title: '住院人信息',
              icon: 'icon-jiuzhenren',
              path: '/zhuyuan-record'
            },
            {
              title: '住院清单',
              icon: 'icon-zhuyuanqingdan',
              path: '/zhuyuanriqingdan'
            },
            {
              title: '住院报告',
              icon: 'icon-zhuyuanqingdan',
              path: '/zhuyuanbaogaorecord'
            }
            // {
            //   title: '住院服务',
            //   icon: 'icon-zhuyuanfuwu'
            // },
            // {
            //   title: '预交记录',
            //   icon: 'icon-zhuyuanyajin'
            // },
            // {
            //   title: '住院日清单',
            //   icon: 'icon-zhuyuanqingdan',
            //   path: '/zhuyuan/list/search'
            // }
          ]
        },
        {
          name: '医院信息',
          active: 2,
          childList: [
            {
              title: '医院介绍',
              icon: 'icon-yiyuanjieshao',
              path: '/yiyuanjieshao'
            },
            // {
            //   title: '科室介绍',
            //   icon: 'icon-keshijieshao',
            //   path: '/keshijieshao'
            // },
            // {
            //   title: '医生介绍',
            //   icon: 'icon-yishengyishengjieshao',
            //   path: '/yishengjieshao'
            // },
            {
              title: '来院导航',
              icon: 'icon-daohangdizhi',
              path: '/tMap'
            // },
            // {
            //   title: '医院动态',
            //   icon: 'icon-dongtai',
            //   path: '/dynamic'
            }
          ]
        },
        {
          name: '我的',
          active: 3,
          childList: [
            {
              title: '我的就诊人',
              icon: 'icon-jiuzhenren',
              path: '/myPatient'
            },
            // {
            //   title: '我的收藏',
            //   icon: 'icon-wodeshoucang'
            // },
            {
              title: '挂号记录',
              icon: 'icon-guahaojiluchaxun',
              path: '/guahaojilu'
            // },
            // {
            //   title: '门诊缴费记录',
            //   icon: 'icon-jiaofeijilu',
            //   path: '/menzhenjiaofeijilu'
            // },
            // {
            //   title: '住院押金补缴记录',
            //   icon: 'icon-zhuyuanyajin'
            // },
            // {
            //   title: '预检分诊表',
            //   icon: 'icon-guahaojiluchaxun',
            //   path: '/yujianfenzhenList'
            }
          ]
        }
      ],
      tabbarList: [
        {
          title: '门诊',
          icon: 'icon-menzhen-01'
        },
        {
          title: '住院',
          icon: 'icon-zhuyuan'
        },
        {
          title: '医院信息',
          icon: 'icon-yiyuanjieshao'
        },
        {
          title: '我的',
          icon: 'icon-wode'
        }
      ],
      tabbarActive: 0,
      showActiveNum: 3
    }
  },
  created() {
    if (this.$route.query && this.$route.query.type) {
      this.changeTabbarActive(this.$route.query.type * 1)
    }
    const userInfo = localStorage.userInfo
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
  },
  mounted () {
    this.tabbarActive = this.$store.state.tabbarActive
  },
  methods: {
    resetCreated(data) {
      this.userInfo = data
    },
    toPage(url) {
      if (!url) return
      // if (url === '/yuyueguahao') {
      //   this.$toast('功能暂无开放！')
      //   return
      // }
      this.$router.push(url)
    },
    changeTabbarActive(index) {
      this.$store.commit('updateInfo', {
        name: 'tabbarActive',
        value: index
      })
    },
    wechatUserInfo() {
      this.wechatUserInfo().then(res => {
        console.log(res)
      })
    },
    toDynamicInfo(id) {
      this.$router.push(`/dynamicInfo?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
  .grid-icon {
    font-size: 30px;
  }

  .grid-text {
    margin-top: 10px;
  }

  .cell-left-icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .van-tabbar-item__icon {
    margin-bottom: 0 !important;
    margin-top: 8px;
  }

  .van-tabbar-item {
    font-size: 14px !important;
  }

  .van-tabbar {
    height: auto !important;
    padding-top: 10px;
  }

  .tabbar-title {
    margin: 5px 0 8px;
  }

  .tabbar-icon {
    font-size: 24px;
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }

  .iconfont-blue{
    color: #1677ff;
  }
  /* .van-swipe-item{
    color: #1677ff;
  }
  .van-notice-bar{
    color: #1677ff;
  } */
</style>
