<template>
  <div>
    <!-- <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    /> -->
    <keep-alive><router-view v-if="keepAlive" /></keep-alive>
    <router-view v-if="!keepAlive"/>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      title: '',
      keepAlive: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.title = val.meta.title
        this.keepAlive = val.meta.keepAlive
      },
      immediate: true
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onClickRight() {}
  }
}
</script>
