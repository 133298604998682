<template>
  <div class="login">
    <van-loading type="spinner" vertical>加载中....</van-loading>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      url: ''
    }
  },
  created() {
    const loginQueryStr = localStorage.loginQuery || ''
    const loginQuery = loginQueryStr && JSON.parse(loginQueryStr)
    const query = this.$route.query
    if (query.openid) {
      localStorage.clear()
      localStorage.setItem('openId', query.openid)
      this.$store.commit('updateInfo', {
        name: 'openId',
        value: query.openid
      })
      localStorage.removeItem('loginQuery')
      const params = { ...loginQuery }
      delete params.url
      const toUrl = loginQuery?.url || '/'
      history.pushState({ page: 1 }, null, window.location.href)
      this.$router.replace({ path: toUrl, query: params })
    } else {
      localStorage.setItem('loginQuery', JSON.stringify(query))
      this.wechatAuthorize()
    }
  },
  methods: {
    wechatAuthorize () {
      const returnUrl = `${window.location.protocol}//${window.location.host}/login`
      this.$Apis.wechatAuthorize({ returnUrl }).then(res => {
        if (res.code === 200) {
          window.location.href = res.data.url
        } else {
          this.$toast('请重新进入')
        }
      }).catch((err) => {
        this.$toast('服务器错误', JSON.stringify(err))
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login{
  /deep/.van-loading{
    text-align: center;
    margin-top: 1rem;
  }
}
</style>
