import Vue from "vue";
import VueRouter from "vue-router";
import NavBar from "../components/NavBar.vue";
import Layout from "../views/layout/layout.vue";
import Login from "../views/login/login.vue";
import hospitalization from './hospitalization'
// import LoginWage from "../views/login/wage.vue";

Vue.use(VueRouter);

const routes = [
  // 主页
  {
    path: "/",
    name: "layout",
    meta: { title: "首页" },
    component: Layout,
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "登录" },
    component: Login,
  },
  {
    path: "/",
    redirect: "/layout",
    component: NavBar,
    children: [
      ...hospitalization,
      {
        path: "/baogaolist",
        meta: { title: "报告查询", keepAlive: true },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/baogaoList"),
      },
      // 常州瑞鸿
      {
        path: "/tijianlist",
        meta: { title: "体检报告列表" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/tijianList"),
      },
      // 常州瑞鸿
      {
        path: "/tijiandetail",
        meta: { title: "体检报告详情" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/tijianDetail"),
      },
      {
        path: "/outpatient",
        meta: { title: "门诊报告", keepAlive: true },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/outpatient"),
      },
      {
        path: "/outpatientJcDetail",
        meta: { title: "检查详情" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/outpatientJcDetail"),
      },
      {
        path: "/outpatientJyDetail",
        meta: { title: "检验详情" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baogao/outpatientJyDetail"),
      },
      /* {
        path: "/reportList",
        meta: { title: "年度福利体检基础套餐" },
        redirect: "/checkAll",
        component: () => import("../views/baogao/physicalExamination/reportList"),
        children: [
          // 异常项
          {
            path: "/anomaly",
            meta: { title: "年度福利体检基础套餐" },
            component: () => import("../views/baogao/physicalExamination/anomaly"),
          },
          // 全部项
          {
            path: "/checkAll",
            meta: { title: "年度福利体检基础套餐" },
            component: () =>import("../views/baogao/physicalExamination/checkAll"),
          },
          // 体验总结
          {
            path: "/experienceSummary",
            meta: { title: "年度福利体检基础套餐" },
            component: () => import("../views/baogao/physicalExamination/experienceSummary"),
          },
        ],
      }, */
      {
        path: "/tijianDetail",
        meta: { title: "体检报告详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/baogao/tijianDetail"
          ),
      },
      {
        path: "/hesuanbaogao",
        meta: { title: "核酸报告" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/hesuanbaogao/index"),
      },
      {
        path: "/hesuanbaogao/search",
        meta: { title: "核酸报告" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/hesuanbaogao/search"
          ),
      },
      {
        path: "/hesuanbaogao/list",
        meta: { title: "核酸报告" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/hesuanbaogao/list"),
      },
      {
        path: "/baogaoDetail",
        meta: { title: "报告详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/baogao/baogaoDetail"
          ),
      },
      {
        path: "/baogaoJcDetail",
        meta: { title: "检查报告详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/baogao/baogaoJcDetail"
          ),
      },
      {
        path: "/guahaojilu",
        name: "guahaojilu",
        meta: { title: "挂号记录" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/guahaojilu/guahaojilu"
          ),
      },
      {
        path: "/myPatient",
        name: "myPatient",
        meta: { title: "我的就诊人" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/myPatient/myPatient"
          ),
      },
      {
        path: "/addPatient",
        name: "addPatient",
        meta: { title: "添加就诊人" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/addPatient/addPatient"
          ),
      },
      {
        path: "/patientInfo",
        name: "patientInfo",
        meta: { title: "就诊人详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/patientInfo/patientInfo"
          ),
      },
      {
        path: "/yuyueguahao",
        name: "yuyueguahao",
        meta: { title: "科室列表", keepAlive: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yuyueguahao/yuyueguahao"
          ),
      },
      {
        path: "/registered",
        name: "registered",
        meta: { title: "预约挂号" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yuyueguahao/registered"
          ),
      },
      {
        path: "/registeredDetails",
        name: "registeredDetails",
        meta: { title: "专家挂号" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yuyueguahao/registeredDetails"
          ),
      },
      {
        path: "/guahaoqueren",
        name: "guahaoqueren",
        meta: { title: "挂号确认" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/guahaoqueren/guahaoqueren"
          ),
      },
      {
        path: "/guahaoxiangqing",
        name: "guahaoxiangqing",
        meta: { title: "挂号详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/guahaoxiangqing/guahaoxiangqing"
          ),
      },
      {
        path: "/yiyuanjieshao",
        name: "yiyuanjieshao",
        meta: { title: "医院介绍" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yiyuanjieshao/yiyuanjieshao"
          ),
      },
      {
        path: "/menzhenjiaofei",
        name: "menzhenjiaofei",
        meta: { title: "门诊缴费" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/menzhenjiaofei/menzhenjiaofei"
          ),
      },
      {
        path: "/menzhenjiaofeijilu",
        name: "menzhenjiaofeijilu",
        meta: { title: "门诊缴费记录" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/menzhenjiaofeijilu/menzhenjiaofeijilu"
          ),
      },
      {
        path: "/jiaofeimingxi",
        name: "jiaofeimingxi",
        meta: { title: "缴费明细" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/jiaofeimingxi/jiaofeimingxi"
          ),
      },
      {
        path: "/pay",
        name: "pay",
        meta: { title: "确认支付" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pay/pay"),
      },
      {
        path: "/yujianfenzhen",
        name: "yujianfenzhen",
        meta: { title: "预检分诊表" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yujianfenzhen/yujianfenzhen"
          ),
      },
      {
        path: "/yujianfenzhenList",
        name: "yujianfenzhenList",
        meta: { title: "预检分诊列表" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yujianfenzhenList/yujianfenzhenList"
          ),
      },
      {
        path: "/menzhenjiaofei",
        name: "menzhenjiaofei",
        meta: { title: "门诊缴费" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/menzhenjiaofei/menzhenjiaofei"
          ),
      },
      {
        path: "/tMap",
        name: "tMap",
        meta: { title: "导航" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/tMap/tMap"),
      },
      {
        path: "/dynamicInfo",
        name: "dynamicInfo",
        meta: { title: "动态详情" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dynamicInfo/dynamicInfo"
          ),
      },
      {
        path: "/dynamic",
        name: "dynamic",
        meta: { title: "医院动态" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/dynamic/dynamic"),
      },
      {
        path: "/myline",
        name: "myline",
        meta: { title: "我的排队" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/myLine/myLine"),
      },
      {
        path: "/keshijieshao",
        name: "keshijieshao",
        meta: { title: "科室介绍" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/keshijieshao/keshijieshao"
          ),
      },
      {
        path: "/yishengjieshao",
        name: "yishengjieshao",
        meta: { title: "医生介绍" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/yishengjieshao/yishengjieshao"
          ),
      },
      {
        path: "/zhuyuan/list/search",
        name: "zhuyuanxinxi",
        meta: { title: "住院信息" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/zhuyuan/search"),
      },
      {
        path: "/zhuyuan/list/dayslist",
        name: "zhuyuanxinxi",
        meta: { title: "住院清单列表" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/zhuyuan/daysList"),
      },
      {
        path: "/zhuyuan/list/detail",
        name: "zhuyuanxinxi",
        meta: { title: "住院清单列表" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/zhuyuan/detail"),
      },
      {
        path: '/yujianfenzhenList',
        name: 'yujianfenzhenList',
        meta: { title: '预检分诊列表' },
        component: () => import(/* webpackChunkName: "about" */ '../views/yujianfenzhenList/yujianfenzhenList')
      },
      {
        path: '/menzhenjiaofei',
        name: 'menzhenjiaofei',
        meta: { title: '门诊缴费' },
        component: () => import(/* webpackChunkName: "about" */ '../views/menzhenjiaofei/menzhenjiaofei')
      },
      {
        path: '/tMap',
        name: 'tMap',
        meta: { title: '导航' },
        component: () => import(/* webpackChunkName: "about" */ '../views/tMap/tMap')
      },
      {
        path: '/dynamicInfo',
        name: 'dynamicInfo',
        meta: { title: '动态详情' },
        component: () => import(/* webpackChunkName: "about" */ '../views/dynamicInfo/dynamicInfo')
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        meta: { title: '医院动态' },
        component: () => import(/* webpackChunkName: "about" */ '../views/dynamic/dynamic')
      },
      {
        path: '/myline',
        name: 'myline',
        meta: { title: '我的排队' },
        component: () => import(/* webpackChunkName: "about" */ '../views/myLine/myLine')
      },
      {
        path: '/keshijieshao',
        name: 'keshijieshao',
        meta: { title: '科室介绍' },
        component: () => import(/* webpackChunkName: "about" */ '../views/keshijieshao/keshijieshao')
      },
      {
        path: '/yishengjieshao',
        name: 'yishengjieshao',
        meta: { title: '医生介绍' },
        component: () => import(/* webpackChunkName: "about" */ '../views/yishengjieshao/yishengjieshao')
      },
      {
        path: '/zhuyuan/list/search',
        name: 'zhuyuanxinxi',
        meta: { title: '住院信息' },
        component: () => import(/* webpackChunkName: "about" */ '../views/zhuyuan/search')
      },
      {
        path: '/zhuyuan/list/dayslist',
        name: 'zhuyuanxinxi',
        meta: { title: '住院清单列表' },
        component: () => import(/* webpackChunkName: "about" */ '../views/zhuyuan/daysList')
      },
      {
        path: '/zhuyuan/list/detail',
        name: 'zhuyuanxinxi',
        meta: { title: '住院清单列表' },
        component: () => import(/* webpackChunkName: "about" */ '../views/zhuyuan/detail')
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
