import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apis from './apis'

import Vant from 'vant'
import 'vant/lib/index.css'

import './assets/css/reset.less'
import './assets/css/border.css'
import './assets/css/index.less'
import './assets/css/common.less'
import './assets/fonts/iconfont.css'

import './utils/rem'

import noData from './components/noData.vue'

// 共用函数集合
import utils from './utils/publicFunction'
Vue.prototype.$Apis = apis

Vue.component('NoData', noData)

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$utils = utils

Vue.use(Vant)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
