import { post, get, put } from '../utils/request'
export default {
  // 微信公众号
  wechatAuthorize: (data) => get('/wechat/authorize', data), // 获取用户信息
  wechatUserInfo: (data) => get('/wechat/userInfo', data), // 获取用户信息
  /* 我的就诊人 */
  getByAppletsId: (data) => get('/api/patient/getByAppletsId', data), // 获取用户信息
  addPatient: (data) => post('/api/patient/addPatient', data), // 新增患者信息
  delPatient: (data) => post('/api/patient/delPatient', data), // 解绑患者信息
  updatePatient: (data) => post('/api/patient/updatePatient', data), // 设置默认就诊人
  getPatientListVo: (data) => get('/api/patient/getPatientListVo', data), // 获取就诊人列表
  getPatientInfo: (data) => get('/api/patient/getPatient', data), // 获取就诊人详情
  patientList: (data) => get('/api/patient/list', data), // 患者信息
  /* 预约挂号 */
  // getAdminList: (data) => post('/admin/list', data), // 获取科室
  deptTree: (data) => get('/api/dept/tree', data), // 根据父节点获取部门树结构
  getAdminList: (data) => post('/admin/szswzrmyy/deptTree', data), // 根据父节点获取部门树结构
  deptList: (data) => get('/api/dept/list', data), // 部门列表搜索
  registerPage: (data) => get('/api/register/registerPage', data), // 就诊记录分页
  registerDetail: (data) => get(`/api/register/detail/${data.id}`), // 挂号详情
  getKeeps: (data) => get('/api/keep/getKeeps', data), // 科室排班
  getKeep: (data) => get('/api/keep/getKeep', data), // 科室排班
  getDoctorList: (data) => get('/api/doctor/getDoctorList', data), // 科室查医生(吴中)
  appointment: (data) => post('/api/register/appointment', data), // 预约挂号
  register: (data) => post('/api/register/szswzrmyy/register', data), // 挂号
  registerCancel: (data) => put('/api/register', data), // 取消挂号
  // registerCancel: (data) => post('api/register/szswzrmyy/cancelReservation', data), // 取消挂号
  /* 医院信息 */
  getGuidebook: (data) => get('/api/guidebook/getGuidebook', data), // 获取就医指南
  hospital: (data) => get('/api/hospital/hospital/one/1', data), // 获取医院介绍
  /* 报告查询 */
  getMZInspectReport: (data) => get('/api/inspection/inspect/czrh/getMZInspectReport', data), // 检查报告列表
  getMZJYReportList: (data) => get('/api/inspection/inspection/czrh/getMZJYReportList', data), // 检验报告列表
  getInspectReport: (data) => get('/api/inspection/inspect/czrh/getInspectReport', data), // 检查报告列表（体检）
  getReportList: (data) => get('/api/inspection/inspection/czrh/getReportList', data), // 检验报告列表（体检）
  getTJReportList: (data) => get('/api/inspection/inspection/czrh/getTJReportList', data), // 体检报告列表
  getInspectReportDetail: (data) => get('/api/inspection/inspection/czrh/getInspectReportDetail', data), // 检查报告详情
  getMZJYReportDetail: (data) => get('/api/inspection/inspection/czrh/getMZJYReportDetail', data), // 检验报告详情
  getReportDetail: (data) => get('/api/inspection/inspection/czrh/getReportDetail', data), // 检验报告详情（体检）
  getTJReportDetail: (data) => get('/api/inspection/inspection/czrh/getTJReportDetail', data), // 体检报告详情
  getEinvoiceDetailsById: (data) => get('/api/chargeDetail/getEinvoiceDetailsById', data), // 获取电子发票
  getNucleicAcidTestReport: (data) => get('/api/inspection/inspection/szswzrmyy/wechat/getNucleicAcidTestReport', data), // 获取核酸检测报告
  /* 缴费 */
  wechatPay: (data) => post('/api/chargeInfo/updateHsjc/cy', data), // 微信支付
  getHSPrice: (data) => get('/api/chargeInfo/getHSPrice', data), // 支付信息

  wechatPayKy: (data) => post('/api/chargeInfo/updateKyjc/cy', data), // 抗原微信支付
  getKYPrice: (data) => get('/api/chargeInfo/getKYPrice', data), // 抗原支付信息

  gjyyPay: (data) => put('/api/chargeInfo/gjyy/pay', data), // 支付
  chargeListPage: (data) => get('/api/chargeInfo/chargeListPage', data), // 门诊缴费列表
  getChargeDetailsByClinicNo: (data) => get('/api/chargeDetail/getChargeDetailsByClinicNo', data), // 缴费明细
  /* 预检分诊表 */
  precheckGetList: (data) => get('/api/precheck/getList', data), // 缴费明细
  precheckAdd: (data) => post('/api/precheck', data), // 缴费明细
  precheckDetail: (data) => get(`/api/precheck/one/${data.id}`), // 缴费明细
  /* 住院信息 */
  getPatientZy: (data) => post('/api/patientzy/wzrmyy/getPatientZy', data), // 获取住院信息
  getPatientZyOrderDetail: (data) => get('/api/patientzy/wzrmyy/getPatientZyOrderDetail', data), // 获取住院每日费用清单
  // 澄阳公众号
  chengyangLogin: (data) => post('/salarySlip/worker/app/sign-in', data), // 登录
  chengyangResetPass: (data) => post('/salarySlip/worker/app/changePassword', data), // 修改密码
  chengyangList: (data) => post('/salarySlip/app/getSalaryList', data), // 工资列表
  chengyangSalaryDetail: (data) => post('/salarySlip/app/getPersonSalaryDetail', data), // 工资明细
  // 常州瑞鸿医院体检
  ruihongGetCzrhRegisterlnfo: (data) => get('/api/inspection/inspect/czrh/getCzrhRegisterInfo', data), //  检查报告列表
  ruihongGetCzrhRegisterInfoDetails: (data) => get('/api/inspection/inspect/czrh/getCzrhRegisterInfoDetails', data), // 检查报告详情列表
  getCzrhTjReportPdf: (data) => get('/api/inspection/inspect/czrh/getCzrhTjReportPdf', data), // 检查报告下载
  /* 住院 */
  addPatientZyBinding: (data) => post('/api/patientzybinding/addPatientZyBinding', data), // 添加患者住院人绑定
  updPatientZyBinding: (data) => post('/api/patientzybinding/updPatientZyBinding', data), // 修改患者住院人绑定
  delPatientZyBindingByID: (data) => get(`/api/patientzybinding/delPatientZyBindingByID/${data}`), // 删除住院人绑定信息
  getPatientZyBindingByID: (data) => get(`/api/patientzybinding/getPatientZyBindingByID/${data}`), // 查看住院人绑定信息
  getPatientZyBindingList: (data) => get(`/api/patientzybinding/getPatientZyBindingList/${data}`), // 患者住院人绑定列表
  getPatientZyInfo: (data) => post('/api/patientzy/getPatientZyInfo', data), // 获取住院费用清单
  getZyInspectionReportInfo: (data) => post('/api/patientzy/getZyInspectionReportInfo2', data), // 获取住院费用清单
  getCzrhBeHospitalizedInfo: (data) => get('/api/inspection/inspect/czrh/getCzrhBeHospitalizedInfo', data), // 住院检查报告
  getCzrhBeHospitalizedInfoDetails: (data) => get('/api/inspection/inspect/czrh/getCzrhBeHospitalizedInfoDetails', data), // 住院检查报告
  // 门诊
  getCzrhReportMz: (data) => get('/api/inspection/inspect/czrh/getCzrhReportMz', data), // 门诊-报告列表
  getCzrhReportZy: (data) => get('/api/inspection/inspect/czrh/getCzrhReportZy', data), // 住院-住院报告
}
