export default [
  {
    path: '/inpatient-inquiry',
    meta: { title: '住院查询' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/InpatientInquiry')
  },
  {
    path: '/inpatient-information',
    meta: { title: '住院人信息' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/InpatientInformation')
  },
  {
    path: '/inpatient-edit',
    meta: { title: '住院人信息' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/InpatientEdit')
  },
  {
    path: '/zhuyuan-record',
    meta: { title: '住院记录' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanRecord')
  },
  {
    path: '/zhuyuanriqingdan',
    meta: { title: '住院日清单' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanRiQingDan')
  },
  {
    path: '/zhuyuanbaogaorecord',
    meta: { title: '住院报告列表' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanBaoGaoRecord')
  },
  {
    path: '/zhuyuanbaogaorecordJC',
    meta: { title: '检查详情' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanBaoGaoRecordJC')
  },
  {
    path: '/zhuyuanbaogaorecordJY',
    meta: { title: '检验详情' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanBaoGaoRecordJY')
  },
  {
    path: '/zhuyuanbaogaodetail',
    meta: { title: '住院报告详情' },
    component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/ZhuYuanBaoGaoDetail')
  },
  // {
  //   path: '/cashier',
  //   meta: { title: '收银台' },
  //   component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/Cashier')
  // },
  // {
  //   path: '/yajinbujiao',
  //   meta: { title: '押金补缴' },
  //   component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/YaJinBuJiao')
  // },
  // {
  //   path: '/yajinbujiaorecord',
  //   meta: { title: '押金补缴列表' },
  //   component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/YaJinBuJiaoRecord')
  // },
  // {
  //   path: '/yajinbujiaodetail',
  //   meta: { title: '押金补缴详情' },
  //   component: () => import(/* webpackChunkName: "hospitalization" */ '../views/hospitalization/YaJinBuJiaoDetail')
  // }
]
