<template>
  <div id="app">
    <keep-alive><router-view v-if="keepAlive" /></keep-alive>
    <router-view v-if="!keepAlive"/>
  </div>
</template>

<script>
export default {
  name: 'layout',
  data() {
    return {
      keepAlive: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.title = val.meta.title
        this.keepAlive = val.meta.keepAlive
      },
      immediate: true
    }
  }
}
</script>

<style lang="less">
.flex-column-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .middle-box {
    flex: 1;
    overflow: auto;
  }
}
</style>
