<template>
  <div class="gj-no-data">
    <img class="no-patient-pic" src="~@/assets/images/no-content.png" alt="">
    <p>{{title}}</p>
  </div>
</template>
<script>
export default {
  name: 'GjNoData',
  props: {
    title: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.gj-no-data {
  text-align: center;
  margin-top: 1rem;
  p{
    line-height: 1rem;
  }
}
</style>
