export default {
  data () {
    return {
      openId: '',
      tabbarActive: -1,
      shouTabBar: false,
      tabbarList: [
        {
          title: '门诊',
          icon: 'icon-menzhen-01'
        },
        {
          title: '住院',
          icon: 'icon-zhuyuan'
        },
        // {
        //   title: '医院信息',
        //   icon: 'icon-yiyuanjieshao'
        // },
        {
          title: '我的',
          icon: 'icon-wode'
        }
      ]
    }
  },
  created() {
    const openId = this.$store.state.openId
    if (openId !== 'null' && openId) {
      this.openId = openId
      const userInfo = this.$store.state.userInfo
      if (!userInfo) {
        this.getByAppletsId()
      }
    } else {
      this.toLogin()
    }
  },
  methods: {
    async getByAppletsId() {
      const res = await this.$Apis.getByAppletsId({ appletsId: this.openId })
      if (res.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.$store.commit('updateInfo', {
          name: 'userInfo',
          value: res.data
        })
      }
      this.resetCreated(res.data || '')
    },
    resetCreated() {},
    toLogin() {
      const url = this.$route.path
      let query = this.$route.query
      query = { ...query, ...{ url } }
      this.$router.push({ path: '/login', query })
    },
    changeTabbarActive(index) {
      this.$store.commit('updateInfo', {
        name: 'tabbarActive',
        value: index
      })
      this.$router.replace('/')
    }
  }
}
