// import wx from 'weixin-js-sdk'
// import { jssdkGetConfig } from '@/apis/wxbg'

/**
 * 获取微信jsdk 权限
 * @param jsapiList
 */
export function jssdkGetConfigFun(jsapiList) {
  // jssdkGetConfig({
  //   url: location.href.split('#')[0] //eslint-disable-line
  // }).then((res) => {
  //   if (res.code === 200) {
  //     const obj = res.data
  //
  //     wx.config({
  //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //       appId: obj.appId, // 必填，公众号的唯一标识
  //       timestamp: obj.timestamp, // 必填，生成签名的时间戳
  //       nonceStr: obj.nonceStr, // 必填，生成签名的随机串
  //       signature: obj.signature, // 必填，签名
  //       jsApiList: jsapiList // 必填，需要使用的JS接口列表
  //     })
  //   }
  // })
}

/**
 * 在 event 中 找到 data-attrbute 当前级找不到时会向父级找
 * @param event
 * @param attribute data- 后面的字符串
 * @param parentClassName 事件委托 的 终止顶层父dom class
 */
export function getAttribute(event, attribute, parentClassName) {
  let target = "";
  let dataInfo = "";
  let isParent = false;

  do {
    if (!dataInfo) {
      target = target.parentElement || event.target;
    }

    dataInfo = target.getAttribute(`data-${attribute}`);
    isParent = target.className.indexOf(parentClassName) !== -1;
  } while (!(dataInfo || isParent));

  return dataInfo;
}

/**
 * 复制文本
 * @param text
 */
export function copyText(text) {
  let flag;
  const textarea = document.createElement("textarea"); // 创建input对象
  const currentFocus = document.activeElement; // 当前获得焦点的元素
  const toolBoxwrap = document.getElementsByTagName("body")[0]; // 将文本框插入到NewsToolBox这个之后
  toolBoxwrap.appendChild(textarea); // 添加元素
  textarea.value = text;
  textarea.focus();

  if (textarea.setSelectionRange) {
    textarea.setSelectionRange(0, textarea.value.length); // 获取光标起始位置到结束位置
  } else {
    textarea.select();
  }

  try {
    flag = document.execCommand("copy"); // 执行复制
  } catch (eo) {
    flag = false;
  }

  toolBoxwrap.removeChild(textarea); // 删除元素
  currentFocus.focus();
  return flag;
}

/**
 * 获取 URL 中的指定参数
 * @param variable 参数名
 * @returns {string|boolean}
 */
export function getQueryVariable(variable) {
  const url2 = window.location.href;
  const temp2 = url2.split("?")[1];
  const pram2 = new URLSearchParams("?" + temp2);
  const val = pram2.get(variable);
  return val || "";
}

/*
 * 函数节流
 */
export function Throttle(fn, t) {
  let last;
  let timer;
  const interval = t || 500;
  return function () {
    const args = arguments;
    const now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
}

/**
 * 根据名字获取 cookie
 * @param NameOfCookie
 * @returns {string|null}
 */
export function getCookie(NameOfCookie) {
  if (document.cookie.length > 0) {
    let begin = document.cookie.indexOf(`${NameOfCookie}=`);
    if (begin !== -1) {
      begin += NameOfCookie.length + 1;
      let end = document.cookie.indexOf(";", begin);
      if (end === -1) end = document.cookie.length;
      return unescape(document.cookie.substring(begin, end));
    }
  }
  return null;
}

// 将日期时间转换为指定格式
function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

// 生成随机数
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  getCookie,
  getAttribute,
  dateFormat,
  copyText,
  getQueryVariable,
  Throttle,
  jssdkGetConfigFun,
  getRandomInt,
};
