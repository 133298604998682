import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    tabbarActive: 0,
    appletsId: '',
    openId: '',
    userInfo: null,
    patientList: []
  },
  mutations: {
    changeTabbarActive(state, data) {
      state.tabbarActive = data
    },
    updateInfo (state, data) {
      state[data.name] = data.value
    },

    resetInfo (state, data) {
      state[data.name] = {}
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
})
